<template>
  <v-card class="min-h-full d-flex" :loading="isLoading">
    <div class="pa-4 min-h-full d-flex flex-column w-full">
      <CardHeader :title="title" :title-tooltip="titleTooltip" class="pb-4" />

      <div class="d-flex align-center flex-grow-1" style="min-height: 300px">
        <slot />
      </div>

      <v-divider class="my-3" />

      <div class="d-flex align-center" style="height: 40px">
        <span class="text-h6 font-weight-bold">
          {{ description }}
        </span>

        <v-spacer />

        <v-btn v-if="to" color="primary" outlined :to="to">
          <span class="text-uppercase">{{ toLabel }}</span>
          <v-icon right> $mdi-chevron-right </v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import CardHeader from '@/components/shared/CardHeader'
export default {
  components: {
    CardHeader,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    titleTooltip: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    to: {
      type: Object,
      default: undefined,
    },
    toLabel: {
      type: String,
      default: 'See all',
    },
  },
}
</script>
